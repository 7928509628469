<template>
    <nuxt />
</template>

<script setup lang="ts">
</script>

<script lang="ts">
    export default {
        middleware: ['auth', 'consultant']
    }
</script>